.logo-container {
  z-index: 0;
  width: 570px;
  height: 650px;
  //opacity: 0;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;

  svg {
    width: 100%;
    height: auto;
    bottom: 0;
    transform: rotateZ(30deg) !important;
  }

  .solid-logo {
    position: absolute;
    top: auto;
    right: auto;
    bottom: auto;
    left: 0;
    margin: auto;
    width: 100%;
    opacity: 0;
    transform: rotateZ(30deg) !important;
    z-index: 1;
    -webkit-animation: 3.5s ease 0s normal forwards 1 fadein;
    animation: 3.5s ease 0s normal forwards 1 fadein;
  }

  @keyframes fadein{
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
  }

  @-webkit-keyframes fadein{
      0% { opacity:0; }
      66% { opacity:0; }
      100% { opacity:1; }
  }
}


.svg-container {
  stroke: #42ddf5;
  stroke-width: 2;
}