/* var(--accent) allows you to change the sidebar and sidebar hover colors */
/* var(--bg) allows for the change in sidebar background colors */
:root {
  --accent: #42ddf5; 
  --bg: #262626;
}


.nav-bar {
  background: var(--bg); 
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;
  font-family: 300 11px/1.4 'Helvetica Neue', 'sans-serif'; 

  .logo {
    display: block;
    padding: 8px 0;

    
    img {
      display: block;
      margin: 8px auto;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
      
    }
    // controls hover effect for Logo
    &:hover {
      transform: translateY(-3px);
      transition-duration: 0.3s;
    }

  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 210px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      font-size: 22px;
      color: #4d4d4e;
      display: block;
      line-height: 51px;
      height: 51px;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }
      // Color of the worded menu items
      // few semitones darker for extra style 
      &:hover {
        color: #35b8cc;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        font-size: 9px;
        letter-spacing: 2px;
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
      }

      &:first-child {
        &:after {
          content: 'HOME';
        }
      }
    }

    a.about-link {
      &:after {
        content: 'ABOUT';
      }
    }

    a.contact-link {
      &:after {
        content: 'CONTACT';
      }
    }

    a.analytics-link {
      &:after {
        content: 'ANALYT';
      }
    }

    a.projects-link {
      &:after {
        content: 'PROJ.';
      }
    }
    
    a.active {
      svg {
        color: var(--accent);
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0;

    li {
      a {
        padding: 7px 0;
        display: block;
        font-size: 20px;
        line-height: 16px;
        
        
        &:hover svg{
          color: var(--accent);
          transform: translateY(-4px);
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
        }
      }
    }
  }

}